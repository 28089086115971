import { SearchMemberOptionsModel } from '@shared/models/search.member.options.model';
import { SearchMemberProspectOptionsModel } from './search.member.prospect.options.model';

export class InstancesModel {
  ref: string;
  type: string;
  value?: string;
  isProspectInfo?: boolean;
  firstName?: string;
  lastName?: string;
  name?: string;
  memberNumber?: string;
  memberType?: string;
  branchId?: number;

  constructor(dto: SearchMemberProspectOptionsModel) {
    this.ref = dto.ref;
    this.type = dto.type;
    this.isProspectInfo = dto.isProspectInfo;
    this.firstName = dto.firstName;
    this.lastName = dto.lastName;
    this.name = dto.name;
    this.memberNumber = dto.memberNumber;
    this.memberType = dto.genre;
    this.branchId = dto.branchId;

  }
}
