import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductUrls } from '@core/constants/endpoints.constants';
import { ApiClient } from '@core/services/api-client.http.service';
import { PaginatedResponseDto, ProductDto, ProductTypeDto } from '@core/services/dto';
import { CampaignsDto } from '@core/services/dto/campaigns.dto';
import { CampaignApiRequest } from '@shared/models/requests/campaign.request.dto';


@Injectable({
  providedIn: 'any'
})
export class ProductApiService {

  constructor(private api: ApiClient) {
  }

  getProductType(): Observable<PaginatedResponseDto<ProductTypeDto>> {
    return this.api.get<PaginatedResponseDto<ProductTypeDto>>(ProductUrls.productTypes);
  }

  getProducts(productTypeId: number): Observable<PaginatedResponseDto<ProductDto>> {
    return this.api.get<PaginatedResponseDto<ProductDto>>(ProductUrls.products, {productTypeId});
  }

  getCampaigns(request: CampaignApiRequest): Observable<PaginatedResponseDto<CampaignsDto>> {
    return this.api.get<PaginatedResponseDto<CampaignsDto>>(ProductUrls.campaigns, request);
  }


  getECMProductTypes(): Observable<PaginatedResponseDto<ProductTypeDto>> {
    return this.api.get<PaginatedResponseDto<ProductTypeDto>>(ProductUrls.productType);
  }

  getECMProduct(productType: string): Observable<PaginatedResponseDto<ProductDto>> {
    return this.api.get<PaginatedResponseDto<ProductDto>>(ProductUrls.product, {productType});
  }
}
