import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConfig } from '@config/app.config';
import { Router } from '@angular/router';
import { AccountTypeEnum, LinkType } from '@shared/enums';
import { AllLinks } from '@core/constants';
import { AccessService } from '@core/services/access.service';
import { GlobalStateService } from '@core/services/global.state.service';
import { PermissionStringEnum } from '@shared/enums';
import { SearchMemberProspectOptionsModel } from '@shared/models/search.member.prospect.options.model';

@Component({
  selector: 'app-linked-instance',
  templateUrl: './linked-instance.component.html',
  styleUrls: ['./linked-instance.component.scss'],
})
export class LinkedInstanceComponent implements OnInit {
  @Input() instance: SearchMemberProspectOptionsModel;
  @Input() readOnly: boolean;
  @Input() showLabel: boolean;
  @Input() memberDocument = false;
  intanceType =false;

  @Output()
  removeItem: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    private accessService: AccessService,
    private global: GlobalStateService
  ) {
    this.router = router;
  }

  ngOnInit(): void {
    if (this.memberDocument && this.instance.type === 'member')
    {
      this.readOnly =true;
    }
      if (this.showLabel === undefined || this.showLabel === null) {
      this.showLabel = false;
    }
    if (this.instance?.value === 'undefined undefined' && this.instance?.type.toLowerCase() === 'member') {
      this.instance.value = 'Default User';
    }
    this.processInstance(this.instance);
  }

  private processInstance(instance: SearchMemberProspectOptionsModel | SearchMemberProspectOptionsModel[]): void {
    if (Array.isArray(instance)) {
      instance.forEach(arrayItem => {
        if (arrayItem) {
          this.instance = arrayItem;
        }
      });
    }
  }

  removeInstance(): void {
    this.removeItem.emit(this.instance);
  }

  open(instance): void {
    if (instance && instance.ref) {
      switch (instance.type) {
        case LinkType.member:
          if (
            this.accessService.checkUserPermissions([
              PermissionStringEnum.ViewMembers,
            ])
          ) {
            this.router.navigate([
              `/${AppConfig.routes.memberProfile.main}`,
              instance.ref,
            ]);
          }
          break;
        case LinkType.prospect:
          if (
            this.accessService.checkUserPermissions([
              PermissionStringEnum.ViewProspectProfile,
            ])
          ) {
            this.router.navigate([
              `/${AppConfig.routes.prospectProfile.main}`,
              instance.ref,
            ]);
          }
          break;
        case LinkType.account:
          if (
            this.accessService.checkUserPermissions([
              PermissionStringEnum.ViewAccounts,
            ])
          ){
           this.global.getMemberAccountData({search: instance.accountNumber}).subscribe(
            (account) => {
              const subType = account?.[0]?.type;
              const MemberId = account?.[0]?.memberId;
              const savingTypes = [AccountTypeEnum.Chequing, AccountTypeEnum.Savings, AccountTypeEnum.TermDeposit];
              const type = savingTypes.includes(subType) ? 'savings' : subType.toLowerCase();
              this.router.navigate([AllLinks.account.details(instance.ref, type, MemberId)]);
            });
          }
          break;
        case LinkType.conversation:
          if (
            this.accessService.checkUserPermissions([
              PermissionStringEnum.ViewConversations,
            ])
          ) {
            this.router.navigate([
              AppConfig.routes.memberManagement.conversationDetailsView(
                instance.ref
              ),
            ]);
          }
          break;
        case LinkType.task:
          if (
            this.accessService.checkUserPermissions([
              PermissionStringEnum.ViewAllTasks,
            ])
          ) {
            this.router.navigate([AllLinks.tasks.details(instance.ref)]);
          }
          break;
        case LinkType.file:
          if (
            this.accessService.checkUserPermissions([
              PermissionStringEnum.ViewMembers,
            ])
          ) {
            this.router.navigate([
              `/${AppConfig.routes.contentManagement.ecm}`,
              instance.ref,
            ]);
          }
          break;
        case LinkType.sale:
          if (
            this.accessService.checkUserPermissions([
              PermissionStringEnum.ViewAllSales,
              PermissionStringEnum.ViewMySales,
            ])
          ) {
            this.router.navigate([AllLinks.sale.details(instance.ref)]);
          }
          break;
      }
    }
  }
}
