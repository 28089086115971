import { AuthUrls } from '@core/constants';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, scheduled, Subject } from 'rxjs';
import { LoaderService } from '@core/services/loader.service';
import { finalize, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '@core/services/auth';
import { SpinnerService } from '@shared/services/spinner.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public count = 0;
  private activeRequests = 0;
  private lastAzureToken: string;
  private acquiringInnerToken = false;
  private innerTokenSubject = new Subject<string>();
  private innerToken$ = this.innerTokenSubject.asObservable();

  constructor(
    private loaderService: LoaderService,
    private authService: AuthService,
    private spinnerService: SpinnerService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const shouldShowLoader = this.shouldShowLoader(req.url);

    if (shouldShowLoader) {
      this.activeRequests++;
      if (this.activeRequests === 1) {
        this.spinnerService.showPermanent();
      }
    }

    return this.authService.getAzureToken()
      .pipe(
        switchMap(azureToken => {
          if (this.count === 0) {
            this.loaderService.setHttpProgressStatus(true);
          }
          this.count++;

          const tokenObs = req.url === AuthUrls.login
            ? of(azureToken)
            : this.getInnerToken(azureToken);

          return tokenObs
            .pipe(
              switchMap(token => {
                //   this.lastAzureToken = azureToken;

                const headers = req.headers
                  .set('Authorization', `Bearer ${token}`)
                  .set('X-Application-Type', 'CRM');

                const authReq = req.clone({ headers });

                return next.handle(authReq).pipe(
                  finalize(() => {
                    this.count--;
                    if (this.count === 0) {
                      this.loaderService.setHttpProgressStatus(false);
                    }
                    if (shouldShowLoader) {
                      this.activeRequests--;
                      if (this.activeRequests === 0) {
                        this.spinnerService.hidePermanent();
                      }
                    }
                  }));
              })
            );
        })
      );
  }

  private getInnerToken(currAzureToken: string): Observable<string> {
    return this.lastAzureToken !== currAzureToken
      ? this.acquireInnerToken(currAzureToken)
      : of(this.authService.getInnerToken());
  }

  private acquireInnerToken(currAzureToken: string): Observable<string> {
    if (this.acquiringInnerToken) {
      return this.innerToken$.pipe(take(1));
    }

    this.acquiringInnerToken = true;

    return this.authService.refreshInnerLogin(false)
      .pipe(
        finalize(() => this.acquiringInnerToken = false),
        tap(token => {
          this.innerTokenSubject.next(token);
          this.lastAzureToken = currAzureToken;
        })
      );
  }

  private shouldShowLoader(url: string): boolean {
    const loadingApiEndpoints = [                           // here define URL EndPoints to showloader permanent
        '/conversation',
        '/conversation-imports',
        '/ScheduleConfiguration',
    ];
    return loadingApiEndpoints.some(endpoint => url.includes(endpoint));
}
}
