import { takeUntil } from 'rxjs/operators';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { GLOBAL_SETTINGS } from '@shared/constants/global-settings';
import { ReferrerCardStateService } from '@shared/components/referrer-card/referrer-card.state.service';
import { fromEvent, Observable, of, Subject } from 'rxjs';
import { ChosenLinkModel, SearchMemberOptionsModel, SearchUserOptionsModel } from '@shared/models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MinLengthToTriggerFormControl } from '@shared/controls/min-length-to-trigger-form-control';
import { InputAutocompleteComponent } from '@shared/components/input-autocomplete/input-autocomplete.component';
import { ReferrersDto } from '@core/services/dto';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { CmButtonComponent } from '@shared/components/cm-button/cm-button.component';

@Component({
  selector: 'app-referrer-card',
  templateUrl: './referrer-card.component.html',
  styleUrls: ['./referrer-card.component.scss']
})
export class ReferrerCardComponent extends autoUnsubscribeMixin() implements OnInit, OnChanges {
  GLOBAL_SETTINGS = GLOBAL_SETTINGS;
  defaultSoldByUserName = new Subject<SearchMemberOptionsModel>();
  referredByUsers$: Observable<SearchUserOptionsModel[]>;
  referredById: string;
  form: FormGroup;
  chosenLinks: ChosenLinkModel[] = [];
  inputVisible = false;
  clickBtn$: Observable<any>;
  btnComponent: CmButtonComponent;
  showPlusButton = true;

  @ViewChild(InputAutocompleteComponent) inputAutocompleteComponent: InputAutocompleteComponent;

  @Output() chosenInstances: EventEmitter<any> = new EventEmitter<any>();
  @Input() preselected: ReferrersDto[];
  @Input()
  set btn(value: CmButtonComponent) {
    this.clickBtn$ = fromEvent((value as any).elRef.nativeElement, 'click');
    this.btnComponent = value;
  }

  constructor(
    private state: ReferrerCardStateService,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.clickBtn$
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe(() => {
        this.inputVisible = true;
      });

    this.form = this.fb.group({
      referredBy: [new MinLengthToTriggerFormControl(null)]
    });

    this.form.controls.referredBy.valueChanges.subscribe(name => {
      if (!name) {
        return;
      }

      if (typeof name === 'object') {
        this.referredById = name.ref;
      }
      else if (name?.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch) {
        this.state.getSearchedUsers(name, this.chosenLinks.map(x => x.ref));
      }
    });
    this.state.referredByUsers$().subscribe(() => {
      this.referredByUsers$ = this.state.referredByUsers$();
    }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.preselected?.currentValue) {
      this.chosenLinks = [];
      if (this.preselected) {
        this.preselected.forEach((item: ReferrersDto) => {
          const referrer = {
            label: item?.firstName + ' ' + item?.lastName,
            ref: item?.userId,
            type: null,
            value: null
          };
          this.addInstance(referrer);
        });
      }
    }
  }

  addInstance(value): void {
    this.inputAutocompleteComponent?.clear();
    this.chosenLinks.push(value);
    this.inputVisible = false;
    this.chosenInstances.emit(this.chosenLinks);
    this.form?.reset();
    this.referredByUsers$ = of([]);

    if (this.chosenLinks.length > 4) {
      this.btnComponent.disabled = true;
    }
  }

  openLink(): void {
    this.inputVisible = true;
  }


  removeFromChosenLinks(value): void {
    this.chosenLinks = this.chosenLinks.filter(item => item.ref !== value.ref);
    this.chosenInstances.emit(this.chosenLinks);
    this.btnComponent.disabled = false;
  }
}
