import { ChangeLogsDto } from '@core/services/dto/change-logs.dto';
import { formateUTCtoTZ } from '@shared/utils';


export class ChangeLogsModel {
    id?: number;
    changeDate: string;
    changedBy: string;
    eventType: string;
    oldValue: string;
    newValue: string;


    constructor(dto: ChangeLogsDto) {
        this.id = dto.id;
        this.changeDate = dto.changeDate ? formateUTCtoTZ(dto.changeDate) : dto.changeDate;
        this.changedBy = dto.changedBy;
        this.eventType = dto.eventType === 'Email' ? 'Email Sent' : dto.eventType;
        this.oldValue = dto.oldValue;
        this.newValue = dto.newValue;

    }


}
