import { UseTypeOptions } from './task-template-modal-dialog.config';
import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import {
  TaskTemplateModalDialogStateService
} from '@shared/components/task-template-modal-dialog/task-template-modal-dialog.state.service';
import { CustomSnackbarService } from '@core/services/custom-snackbar.service';
import {
  PriorityOptions,
  TypeOptions,
  DueDateType
} from '@shared/components/task-template-modal-dialog/task-template-modal-dialog.config';
import { TaskTemplate } from '@shared/models/task-template';
import { AttachmentModel, SearchMemberOptionsModel, SearchUserOptionsModel } from '@shared/models';
import { EmailTemplateDto } from '@core/services/dto';
import { STATUS_ACTIVE_INACTIVE_OPTIONS } from '@shared/constants/select-options.constants';
import { FileTypeEnum, TaskTemplatePriorityEnum, TaskTypeEnum } from '@shared/enums';
import { CreateConversationStateService } from '../conversation/create-conversation.state.service';
import { GLOBAL_SETTINGS } from '@shared/constants/global-settings';
import { Observable, Subject } from 'rxjs';
import { AssigneeFormControl } from '@shared/controls/assignee-form-control';
import { GlobalStateService } from '@core/services/global.state.service';
import { CreateTaskStateService } from '../create-task/create-task.state.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { SMSTemplateDto } from '@core/services/dto/sms-template.dto';

@Component({
  selector: 'app-task-template-modal-dialog',
  templateUrl: './task-template-modal-dialog.component.html',
  styleUrls: ['./task-template-modal-dialog.component.scss']
})
export class TaskTemplateModalDialogComponent extends autoUnsubscribeMixin() implements OnInit, AfterContentInit {
  form: FormGroup;
  isDisabled = false;
  statusOptions = STATUS_ACTIVE_INACTIVE_OPTIONS;
  typeOptions = TypeOptions;
  priorityOptions = PriorityOptions;
  useTypeOptions = UseTypeOptions;
  dueDateType = DueDateType;
  emailTemplates: number[] = [];
  smsTemplates: number[] = [];
  taskTemplate: TaskTemplate;
  files: AttachmentModel[];
  isEditMode = false;
  preselectedEmailTemplates: EmailTemplateDto[] = [];
  preselectedSmsTemplates: SMSTemplateDto[] = [];
  FileTypeEnum = FileTypeEnum;
  GLOBAL_SETTINGS = GLOBAL_SETTINGS;
  conversationUsers$!: Observable<any>;
  selectedUser: any;
  dataCome: any;
  public isEdit = true;
  assignedId: any;
  ischanged = false;
  defaultAssigneeUserName = new Subject<SearchUserOptionsModel>();
  defaultUser: any;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  @Output() closeDialog = new EventEmitter<{ changesMade: boolean }>();
  @Input() Id?;

  constructor(
    private fb: FormBuilder,
    private state: TaskTemplateModalDialogStateService,
    private toast: CustomSnackbarService,
    private service: CreateTaskStateService,
    private globalState: GlobalStateService

  ) {
    super();
    this.conversationUsers$ = this.service.users$();
    // for default user
    // this.defaultUser = {
    //   label: this.globalState.user.firstName + ' ' + this.globalState.user.lastName,
    //   ref: this.globalState.user.id,
    //   type: null,
    //   value: null
    // };
    this.selectedUser = null;
    this.form = this.fb.group({
      name: ['', [Validators.maxLength(50), Validators.required]],
      status: ['', Validators.required],
      type: [TaskTypeEnum[TaskTypeEnum.General], Validators.required],
      useType: [null, Validators.required],
      priority: [TaskTemplatePriorityEnum[TaskTemplatePriorityEnum.High], Validators.required],
      description: ['', [Validators.maxLength(1000), Validators.minLength(3), Validators.required]],
      dueIn: ['', Validators.max(99)],
      dueDateType: [''],
      assigneeId: ['']
    });
  }

  ngOnInit(): void {
    this.form.get('assigneeId').valueChanges.subscribe(name => {
      if (!name) {
        return;
      }

      if ((name?.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch)) {
        this.service.getTaskListSearchedUserGroups(name);
      }
    });
    if (this.Id) {
      this.isEditMode = true;
      this.state.getTaskTemplate(this.Id).subscribe(task => {
        let fullName;
        if (task.assigneeId?.isGroup) {
          fullName = task.assigneeId?.name;
          this.assignedId = task.assigneeId?.id;
        } else if (task.assigneeId?.isGroup === false) {
          fullName = `${task.assigneeId?.firstName} ${task.assigneeId?.lastName}`;
        }
        else {
          fullName = null;
        }
        this.files = task.attachments;
        this.taskTemplate = task;
        this.form = this.fb.group({
          name: this.taskTemplate.name,
          status: this.taskTemplate.status,
          type: this.taskTemplate.type,
          useType: this.taskTemplate.useType,
          priority: this.taskTemplate.priority,
          description: this.taskTemplate.description,
          dueIn: this.taskTemplate.timeDue.dueIn,
          dueDateType: this.taskTemplate.timeDue.dueDateType,
          assigneeId: fullName
        });
        this.selectedUser = { ...task, name: fullName };

        if (task.emails?.length) {
          this.state.getEmailTemplates(task.emails).subscribe(response => {
            this.preselectedEmailTemplates = response.data;
          });
        }
        if (task.sms?.length) {
          this.state.getSmsTemplates(task.sms).subscribe(response => {
            this.preselectedSmsTemplates = response.data;
          });
        }
      });
    }
    this.isEdit = false;
  }

  ngAfterContentInit(): any {
    if (this.autocompleteTrigger && this.autocompleteTrigger.panelOpen) {
      this.autocompleteTrigger.closePanel();
    } else if (this.autocompleteTrigger) {
      this.autocompleteTrigger.openPanel();
    }
  }
  onchangemat(): any {
    this.isEdit = true;
    this.form.get('assigneeId').valueChanges.subscribe(name => {
      if (!name) {
        return;
      }
      if ((name?.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch)) {
        this.service.getSearchedUserGroups(name);
      }
      this.ischanged = true;
    });
  }

  disableForm(event: boolean): void {
    this.isDisabled = event;
  }


  record(): void {
    this.taskTemplate = {
      ...this.form.value,
      attachments: this.form.value.attachments.map(x => x.id)
    };
    if (this.ischanged) {
      if (this.selectedUser.assigneeId != null) {
        this.selectedUser.assigneeId = '';
      }
      else {
        this.selectedUser.assigneeId = null;
      }
    }
    if (this.form.value.assigneeId?.ref) {
      this.taskTemplate.assigneeId = this.form.value.assigneeId?.ref;
    }
    else {
      this.taskTemplate.assigneeId = this.selectedUser?.assigneeId?.id;
    }
    if (this.form.value.dueIn && this.form.value.dueDateType) {
      this.taskTemplate.timeDue = { dueIn: this.form.value.dueIn, dueDateType: this.form.value.dueDateType };
    }
    this.taskTemplate.emails = this.isEmailType() ? this.emailTemplates : [];
    this.taskTemplate.sms = this.isSMSType() ? this.smsTemplates : [];

    if (this.Id) {
      this.state.updateTaskTemplate(this.Id, this.taskTemplate).subscribe(data => {
        this.showToast(data);
      });
    } else {
      this.state.createTaskTemplate(this.taskTemplate).subscribe(data => {
        this.showToast(data);
      });
    }
  }

  showToast(data?): void {
    if ('id' in data) {
      this.toast.success('Template successfully saved');
      this.closeDialog.emit({changesMade: true});
    }
  }

  updateDueIn(): void {
    const dueDateType = this.form.controls.dueDateType;
    this.form.controls.dueIn.value ? dueDateType.setValidators(Validators.required) : dueDateType.clearValidators();
    dueDateType.updateValueAndValidity();
  }

  addEmail(selectedIds): void {
    this.emailTemplates = selectedIds;
  }

  isEmailType(): boolean {
    return this.form.controls.type.value === 'Email';
  }
  isSMSType(): boolean {
    return this.form.controls.type.value === 'SMS';
  }
  addSMS(selectedIds): void {
    this.smsTemplates = selectedIds;
  }
  getOptionText(option): any {
    return option?.label;
  }

}
