import { MemberProfileModel } from '@shared/models/index';

export class SearchMemberOptionsModel {
  label?: string;
  value?: string;
  ref: string;
  type: string;
  disabled?: boolean;
  cellPhone?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  memberNumber?: string;

  constructor(dto: MemberProfileModel) {
    if (dto.memberInfo.organizationName) {
      this.label = `${dto.memberNumber} - ${dto.memberInfo.organizationName}`;
      this.value = dto.memberInfo.organizationName;
      this.name = dto.memberInfo.organizationName;
    } else {
      this.label = `${dto.memberNumber} - ${dto.memberInfo.firstName} ${dto.memberInfo.lastName}`;
      this.value = `${dto.memberInfo.firstName} ${dto.memberInfo.lastName}`;
      this.name =  `${dto.memberInfo.firstName} ${dto.memberInfo.lastName}`;
    }
    this.type = 'member';
    this.ref = dto.memberId?.toString();
    this.cellPhone = dto.cellPhone?.toString();
    this.firstName = dto.memberInfo.firstName;
    this.lastName = dto.memberInfo.lastName;
    this.memberNumber =dto.memberNumber;
  }
}
