import { Moment } from 'moment';
import * as moment from 'moment';
import * as mt from 'moment-timezone';
import { GLOBAL_SETTINGS } from '@shared/constants/global-settings';

export const DEFAULT_DATE_FORMAT = 'DD-MM-yyyy';
export const DATE_WITH_TIME_FORMAT = 'DD-MM-yyyy HH:mm';
export const DATE_FORMAT_FOR_TASK_REPORTS = 'yyyy-MM-DD';

export function formatDateToString(date: Date | Moment | string, dateFormat: string = DEFAULT_DATE_FORMAT): string {
  const endOfDate = moment(date).endOf('day').utc();
  if (endOfDate.utc().format() <= moment(new Date()).utc().format()) {
    return `overdue (${moment(endOfDate, 'YYYYMMDD').fromNow(true)})`;
  }
  return moment(date).format(dateFormat);
}

export function formatDateSimple(date: Date | Moment | string, dateFormat: string = DEFAULT_DATE_FORMAT): string {
  return moment(date).format(dateFormat);
}

export function formatUTCDateSimple(date: Date | Moment | string, dateFormat: string = DEFAULT_DATE_FORMAT): string {
  return moment.utc(date).format(dateFormat);
}

export function formatDateToLocalSimple(date: Date | Moment | string, dateFormat: string = DEFAULT_DATE_FORMAT): string {
  return moment(new Date(date.toLocaleString() + 'Z')).format(dateFormat);
}

export function getEndOfDate(date: Date): Date {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);

  return newDate;
}

export function getSameStartOfDate(date: any): Date | null {
  const parsedDate = date instanceof Date ? date : new Date(date);
  if (isNaN(parsedDate.getTime())) {
    return null;
  }
  return new Date(Date.UTC(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate()));
}

export function getSameEndOfDate(date: Date): Date {
  const parseDate = new Date(date);
  parseDate.setUTCHours(23, 59, 59, 999);
  return parseDate;
}

export function getStartOfDate(date: Date): Date {
  const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  return newDate;
}

export function getLocalDateAsString(date: Date): string {
  return moment(getStartOfDate(new Date(date))).format();
}

export function getClientTimezoneOffset(): number {
  return -new Date().getTimezoneOffset();
}

export function getAge(birth, death?): number {
  const birthDate = new Date(birth);
  const fromDate = death ? new Date(death) : new Date();
  return moment(fromDate).diff(birthDate, 'years');
}

export function formatDateSimpleforReport(date: Date | Moment | string, dateFormat: string = DATE_FORMAT_FOR_TASK_REPORTS): string {
  return moment(date).format(dateFormat);
}
export function formateDateTimeSimple(date: Date | string, dateFormat: string = 'YYYY-MM-DD, HH:mm:ss'): string {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return moment.utc(date).format(dateFormat);
}
export function profileDateTimeformate(date: Date | string, dateFormat: string = 'DD-MM-YYYY, HH:mm'): string {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return moment.utc(date).format(dateFormat);
}

export function formateUTCtoTZ(date: Date | string, dateFormat: string = 'DD-MM-YYYY hh:mm A'): string {
  const timeZone = localStorage.getItem(GLOBAL_SETTINGS.timeZoneKey) || 'Canada/Eastern';
  // The API always returns the date in UTC.
  // But it's formate is not in UTC formate, convert it to UTC first,
  // then change it to the desired timezone.
  if (typeof date === 'string' && !date.includes('Z')) {
    const utcMoment = moment.utc(date);
    return utcMoment.tz(timeZone).format(dateFormat);
  }

  return mt(date).tz(timeZone).format(dateFormat);
}

// Convert any date from given timezone to desired timezone
export function formateTZtoTZ(date: Date | string, fromTZ = 'Canada/Eastern', dateFormat: string = 'DD-MM-YYYY hh:mm A'): string {
  const utcDate = mt.tz(date, fromTZ).utc().format();
  return formateUTCtoTZ(utcDate, dateFormat);
}

export function isLessThanToday(date: string, format: string = 'YYYY-MM-DD'): boolean {
  const givenDate = moment(date, format);
  const currentDate = moment().startOf('day');
  return givenDate.isBefore(currentDate, 'day');
}

export function transformDateToMMDDYYYY(date: string) {
  return moment(date, 'DD-MM-YYYY hh:mm A').format('MM-DD-YYYY hh:mm A');
}

// example output January 29th, 2025 12:00 AM
export function formatDateWithOrdinal(dateString: string): string {
  return moment(dateString).format('MMMM Do, YYYY h:mm A');
}

// example output January 23rd, 2025 - January 30th, 2025
export function formatDateRangeWithOrdinal(dateRange: string): string {
  const [start, end] = dateRange.split(' - ').map(date => moment(date.trim()));
  return `${start.format('MMMM Do, YYYY')} - ${end.format('MMMM Do, YYYY')}`;
}

export function parseCustomDate(dateString: string): Date {
  // Remove ordinal suffixes (th, st, nd, rd) from the date string
  const cleanedDateString = dateString.replace(/(\d+)(st|nd|rd|th)/, '$1');
  const formattedDateString = cleanedDateString.replace('12:00 AM', '00:00');
  return new Date(formattedDateString);
}
