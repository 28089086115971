import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaskApiService, EmailTemplatesApiService } from '@core/services/api.services';
import { TaskTemplate } from '@shared/models/task-template';
import { SearchEmailTemplatesDto } from '@core/services/dto';
import { SMSTemplatesApiService } from '@core/services/api.services/sms-templates-api.service';
import { SearchSMSTemplatesDto } from '@core/services/dto/search-sms-template.dto';

@Injectable({ providedIn: 'any' })

export class TaskTemplateModalDialogStateService {
  constructor(
    private service: TaskApiService,
    private emailService: EmailTemplatesApiService,
    private smService: SMSTemplatesApiService

  ) {

  }

  createTaskTemplate(taskTemplate: TaskTemplate): Observable<TaskTemplate> {
    return this.service.createTaskTemplate(taskTemplate);
  }

  getTaskTemplate(id: number): Observable<TaskTemplate> {
    return this.service.getTaskTemplate(id)
    .pipe(
      map(taskTemplateDto => new TaskTemplate(taskTemplateDto))
    );
  }

  updateTaskTemplate(id: number, taskTemplate: TaskTemplate): Observable<TaskTemplate> {
    return this.service.updateTaskTemplate(id, taskTemplate);
  }

  getEmailTemplates(ids: number[]): Observable<SearchEmailTemplatesDto> {
    return this.emailService.searchTemplates({includeIds: ids, templateType: 'Email'});
  }
  getSmsTemplates(ids: number[]): Observable<SearchSMSTemplatesDto> {
    return this.smService.searchTemplates({includeIds: ids, templateType: 'SMS'});
  }
}
