import { Component, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { InputAutocompleteComponent } from '../input-autocomplete/input-autocomplete.component';
import { FormControl, FormGroup } from '@angular/forms';
import { GLOBAL_SETTINGS } from '@shared/constants/global-settings';
import { startWith, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AddSmsTemplateStateService } from './add-sms-template-state.service';
import { SearchSMSTemplatesOptionsModel } from '@shared/models/search.sms-templates.options.model';
import { SMSTemplateDto } from '@core/services/dto/sms-template.dto';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-add-sms-template',
  templateUrl: './add-sms-template.component.html',
  styleUrls: ['./add-sms-template.component.scss']
})
export class AddSmsTemplateComponent implements OnChanges, OnDestroy {
  constructor(
    private state: AddSmsTemplateStateService
  ) { }

  smsTemplate: any;
  notFirstChange: boolean;
  selected: SearchSMSTemplatesOptionsModel[] = [];
  excludedIds: number[] = [];
  excludedName: string[] = [];

  GLOBAL_SETTINGS = GLOBAL_SETTINGS;

  @ViewChild(InputAutocompleteComponent) inputAutocompleteComponent: InputAutocompleteComponent;
  @Input() preselected: SMSTemplateDto[];
  @Output() chosenSMS: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() chosenSmsTemplateName: EventEmitter<string[]> = new EventEmitter<string[]>();

  form = new FormGroup({
    smsTemplate: new FormControl()
  });

  templates$ = this.form.get('smsTemplate').valueChanges.pipe(
    startWith(''),
    switchMap(name => {
      if (typeof name === 'string' && name.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch) {
        this.notFirstChange = true;
        this.state.getTemplates(name, this.excludedIds);
      } else {
        if (this.notFirstChange) {
          return of([]);
        }
      }
      return this.state.templates$();
    })
  );

  ngOnChanges(): void {
    if (this.preselected && this.preselected.length) {
      this.selected = this.preselected.map(item => new SearchSMSTemplatesOptionsModel(item));
    }
    this.selected.forEach(item => {
      this.excludedIds = [...new Set(this.selected.map(item => item.ref))];
      this.excludedName = [...new Set(this.selected.map(item => item.label))];
      this.chosenSmsTemplateName.emit(this.excludedName);
    });
  }

  addInstance(event): void {
    this.inputAutocompleteComponent.clear();
    this.selected.push(event);
    this.excludedIds.push(event.ref);
    this.chosenSMS.emit(this.excludedIds);
    this.excludedName.push(event.label);
    this.chosenSmsTemplateName.emit(this.excludedName);
  }

  remove(event): void {
    this.excludedIds.splice(this.excludedIds.findIndex(item => item === event.ref), 1);
    this.selected.splice(this.selected.findIndex(item => item.ref === event.ref), 1);
    this.chosenSMS.emit(this.excludedIds);
    this.excludedName.splice(this.excludedName.findIndex( item => item === event.label), 1);
    this.chosenSmsTemplateName.emit(this.excludedName);
  }

  ngOnDestroy(): void {
    this.selected = [];
    this.excludedIds = [];
    this.preselected = [];
  }

}



